import * as Yup from "yup";
import { useForm, yupResolver } from "@mantine/form";
import {
  NumberInput,
  TextInput,
  Button,
  Box,
  Group,
  PasswordInput,
  Paper,
  Title,
  createStyles,
  Container,
  Text,
} from "@mantine/core";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { createNotification } from "../Notifications";
import { setUser, setCredentials, setOrg, setRole, setMedicalGroup } from "../RequireAuth/authSlice";
import { getProviderOrgList, getProviderRoleList, signIn } from "../../API";
import { useNavigate } from "react-router-dom";
import { Layout } from "../Layout";
import bgImg from "../../assets/medical_icon_image.jpeg";
import { setOrgId } from "../../utils";
import { SUBMITTER_STAFF_ROLE_ID, STAFF_RESPONDER, STAFF_SUBMITTER, OTHER } from "../../constants";

const useStyles = createStyles((theme, _params, getRef) => ({
  inputBox: {
    width: "250px",
    marginBottom: "1em",
    fontFamily: "Open Sans",
    color: "#495057",
  },
  linkText: {
    textAlign: "center",
    color: "#495057",
    fontWeight: "bold",
    fontSize: "12px",
    fontFamily: "Open Sans",
    "&:hover": { color: theme.fn.lighten("#495057", 0.05), cursor: "pointer" },
  },
  button: {
    backgroundColor: "#4A7CBD",
    width: "100px",
    borderRadius: "10px",
    "&:hover": {
      backgroundColor: theme.fn.darken("#4A7CBD", 0.05),
    },
  },
}));

const schema = Yup.object().shape({
  email: Yup.string().email("Invalid email"),
});

export const Login = () => {
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const { classes } = useStyles();
  const form = useForm({
    schema: yupResolver(schema),
    initialValues: {
      email: "",
      password: "",
    },
  });

  const getUserDetails = async (data) => {
    if (data.role_id) {
      //Staff Responder
      dispatch(setOrg(data.org_id));
      dispatch(setMedicalGroup(data.medical_group));
      dispatch(setRole(STAFF_RESPONDER));
    } else {
      const providerRoleList = await getProviderRoleList(data.user_id, data.token);
      const roleIndex = providerRoleList.findIndex(role => role.role_id === SUBMITTER_STAFF_ROLE_ID);
      if (roleIndex !== -1) {
        //Staff Submitter
        const providerOrgList = await getProviderOrgList(data.user_id, data.token);
        dispatch(setOrg(providerOrgList[0].org_id));
        dispatch(setMedicalGroup(data.medical_group));
        dispatch(setRole(STAFF_SUBMITTER));
      } else {
        //Other user
        dispatch(setRole(OTHER));
      }
    }
  };

  return (
    <div
      style={{
        backgroundImage: `url(${bgImg})`,
        backgroundSize: "cover",
        backgroundColor: "#4A7CBD",
        height: "calc(100vh - 80px)",
        width: "100vw",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Paper
        sx={{
          width: "35em",
          height: "25em",
          padding: "3em",
          backgroundColor: "white",
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
        }}
        mx="auto"
        shadow="md"
      >
        <form
          onSubmit={form.onSubmit(async (values) => {
            setLoading(true);
            try {
              const data = await signIn(values.email, values.password);
              dispatch(setUser(data));
              dispatch(setCredentials(values.email, values.password));
              await getUserDetails(data);
              setLoading(false);
              navigate("/");
            } catch (error) {
              setLoading(false);
              if (error.response) {
                if (error.response.status === 703) {
                  createNotification(
                    "Invalid Credentials",
                    "Please check your email and password"
                  );
                  setErrorMsg("Invalid Credentials");
                } else {
                  createNotification(
                    "Something went wrong",
                    "Please try again later"
                  );
                }
              } else {
                createNotification(
                  "Something went wrong",
                  "Please try again later"
                );
              }
            }
          })}
        >
          <Title
            order={2}
            align="center"
            style={{
              color: "#4D7ABE",
              marginBottom: "1em",
              color: "#4D7ABE",
              fontWeight: "bold",
              fontSize: "30px",
              fontFamily: "Open Sans",
            }}
          >
            Welcome Back
          </Title>
          <TextInput
            required
            label="Email"
            className={classes.inputBox}
            {...form.getInputProps("email")}
          />
          <PasswordInput
            required
            label="Password"
            className={classes.inputBox}
            error={errorMsg}
            {...form.getInputProps("password")}
          />
          <Text
            className={classes.linkText}
            onClick={() =>
              window.open(`https://afya.chat/afya/forgotpassword`, "_blank")
            }
          >
            Forgot password?
          </Text>
          <Group position="center" mt="xl">
            <Button type="submit" className={classes.button} loading={loading}>
              Login
            </Button>
          </Group>
        </form>
      </Paper>
    </div>
  );
};
