import { initializeApp } from "firebase/app";
import { getStorage } from "firebase/storage";

const env = process.env.REACT_APP_ENV;

const config = require(`./${env}.firebase.js`);
const app = initializeApp(config);
const storage = getStorage(app);

export { storage };
