import { Document, Page, pdfjs } from "react-pdf";
import * as Yup from "yup";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { TextInput, Button, createStyles, Text, Group } from "@mantine/core";
import { useForm, yupResolver } from "@mantine/form";
import { incrementFormPage, resetFormData } from "./referralSlice";
import { sendFax } from "../../API";
import { createNotification } from "../Notifications";
import PhoneInput from "react-phone-number-input/input";
import { isPossiblePhoneNumber } from "react-phone-number-input";

const useStyles = createStyles((theme, _params, getRef) => ({
  inputLabel: { fontSize: "14px", textAlign: "left" },
  button: {
    backgroundColor: "#E17A3D",
    height: "40px",
    borderRadius: "10px",
    "&:hover": {
      backgroundColor: theme.fn.darken("#E17A3D", 0.05),
    },
  },
}));

const schema = Yup.object().shape({});

export const FaxView = ({ setModal }) => {
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);
  const [faxNumber, setFaxNumber] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [faxIsFocused, setFaxIsFocused] = useState(false);
  const { classes } = useStyles();
  const form = useForm({
    schema: yupResolver(schema),
    initialValues: {},
  });
  const downloadURL = useSelector((state) => state.referral).downloadURL;
  const auth_token = useSelector((state) => state.auth).token;
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        height: "700px",
        width: "100%",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <form
        onSubmit={form.onSubmit(async (values) => {
          if (!isPossiblePhoneNumber(faxNumber)) return;

          setIsLoading(true);
          try {
            const res = await sendFax(faxNumber, downloadURL, auth_token);
            setIsLoading(false);
            setModal(false);
            createNotification(
              "Fax sucessfully queued",
              "Fax will be sent shortly",
              "SUCCESS"
            );
            dispatch(resetFormData());
          } catch (error) {
            setIsLoading(false);
            createNotification(
              "A problem occured",
              "The fax could not be sent"
            );
            console.error("error in sendFax", error);
          }
        })}
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          width: "80%",
          height: "150px",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            fontSize: "3px",
            alignItems: "flex-start",
            justifyContent: "flex-start",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              fontSize: "3px",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Text className={classes.inputLabel} style={{ marginRight: "5px" }}>
              Recipient Fax Number
            </Text>
            <Text className={classes.inputLabel} style={{ color: "orange" }}>
              *
            </Text>
          </div>
          <PhoneInput
            className={classes.phoneInput}
            name="faxNumber"
            country="US"
            value={faxNumber}
            style={{
              width: "200px",
              height: "35px",
              border: errorMsg ? "1px solid red" : "1px solid #CED4DA",
              borderRadius: "5px",
            }}
            onChange={setFaxNumber}
            onFocus={() => {
              setErrorMsg("");
            }}
            onBlur={() => {
              if (!isPossiblePhoneNumber(faxNumber)) {
                setErrorMsg("Fax number is invalid or missing");
              }
            }}
          />
          <Text style={{ color: "red", fontSize: "11px", marginTop: "2px" }}>
            {errorMsg}
          </Text>
        </div>
        <Button type="submit" className={classes.button} loading={isLoading}>
          Send Referral
        </Button>
      </form>
      <iframe
        src={downloadURL}
        style={{ borderRadius: "10px", height: "700px", width: "80%" }}
      />
    </div>
  );
};
