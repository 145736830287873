import { Radio, RadioGroup } from '@mantine/core';

import styles from '../../index.module.css';
const {section, sectionTitle, divider, label} = styles;

const CPT = ({children, form}) => {
  return (
    <section className={section} style={{position: 'relative'}}>
        <h3 className={sectionTitle}>CPT Codes</h3>
        {children}

        <RadioGroup
          name="cptQuantity"
          label="Requesting Provider allows IEHP to modify CPT Codes and Quantity as needed?"
          required
          classNames={{label}}
          style={{marginTop: '1.5rem'}}
        >
          <Radio value="yes" label="Yes" />
          <Radio value="no" label="No" />
        </RadioGroup>
        <div className={divider}></div>
    </section>
  )
}

export default CPT