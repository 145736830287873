import { Skeleton } from "@mantine/core";
import { getClient } from "../../utils";

const TableSkeleton = () => {
  const clientSite = getClient();

  let numberOfColumns = 6;

  if (clientSite.client === 'iehp') {
    numberOfColumns = 11;
  }

  const columns = [];
  for (let i = 0; i < numberOfColumns; i++) {
    columns.push(
      <td key={i}>
        <Skeleton height={8} mt={15} width="75%" radius="xl" />
      </td>
    );
  }
  const rows = [];

  for (let i = 0; i < 12; i++) {
    rows.push(<tr key={i}>{columns.map((col) => col)}</tr>);
  }

  return <tbody>{rows.map((row) => row)}</tbody>;
};
export default TableSkeleton;
