import { forwardRef } from "react";
import { Group, Text } from "@mantine/core";
import { formatName } from "../../utils";

const PCPSelectItem = forwardRef(({ first_name, last_name, npi }, ref) => {
  return (
    <div ref={ref}>
      <Group
        noWrap
        direction="column"
        align="center"
        position="center"
        style={{ borderColor: "blue" }}
      >
        <Text size="med">{formatName(first_name, last_name)}</Text>
        <Text size="xs" style={{ color: "gray" }}>
          {npi}
        </Text>
      </Group>
    </div>
  );
});

export default PCPSelectItem;
