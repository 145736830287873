import { Badge } from "@mantine/core";

const statusColors = {
  "Fax Queued": "grape",
  "Fax Sending": "green",
  "Fax Processing": "green",
  "Fax Delivered": "green",
  "eCARE Completed": "green",
  "Fax Failed": "red",
  "Needs Completion": "orange",
  "Referral Processing": "yellow",
};
const StatusBadge = ({ status }) => {
  return <Badge color={statusColors[status]}>{status}</Badge>;
};

export default StatusBadge;
