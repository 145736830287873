import React from 'react';

import SimpleBar from 'simplebar-react';
import 'simplebar/dist/simplebar.min.css';

import './index.less';

const CustomScrollbars = (props) => {
    return (
        <SimpleBar style={{ height: '100%' }}>
            {props.children}
        </SimpleBar>
    )
}

export default CustomScrollbars;
