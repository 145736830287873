import { createStyles } from "@mantine/core";

const useFilterStyles = createStyles((theme, _params, getRef) => ({
  filterStyles: {
    marginRight: '1.5rem',
    label: {
      color: "#4D7ABE",
      fontWeight: "bold",
      fontFamily: "Open Sans",
      fontSize: "14px",
    },
    input: {
      width: "180px",
      marginTop: "2px",
      height: "30px",
      borderRadius: "15px",
      borderColor: "#4D7ABE",
    },
  },
}));

export default useFilterStyles;
