import { configureStore } from "@reduxjs/toolkit";
import referralReducer from "./components/Dashboard/referralSlice";
import authReducer from "./components/RequireAuth/authSlice";

export const store = configureStore({
  reducer: {
    auth: authReducer,
    referral: referralReducer,
  },
  devTools: true,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware(),
});
