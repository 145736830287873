export const planOptions = [
  { value: "MEDI-CAL", label: "Medi-Cal" },
  { value: "AIM", label: "AIM" },
];

export const selectOptions = [
  { value: "MEDCORE", label: "Medcore" },
  { value: "SUTTER", label: "Sutter" },
  { value: "HCS", label: "HCS" },
];

export const planTypeOptions = [
  { value: "PREMIER", label: "Premier" },
  { value: "RETIREE", label: "Retiree (w/ out Medicare)" },
  { value: "PLAN-C", label: "Plan C" },
];

export const specialties = [
  "Anesthesiology",
  "Cardiology",
  "Dermatology",
  "Endocrinology",
  "Gastroenterology",
  "General surgery",
  "Gynecology",
  "Hematology",
  "Hematology/Oncology",
  "Hepatology",
  "Infectious diseases",
  "Nephrology",
  "Neurology",
  "Neurosurgery",
  "Obstetrics",
  "Obstetrics/Gynecology",
  "Oncology",
  "Ophthalmology",
  "Orthopedics",
  "Otolaryngology",
  "Pediatrics",
  "Physical Medicine and Rehabilitation",
  "Podiatry",
  "Primary Care",
  "Pulmonology",
  "Radiology",
  "Rheumatology",
  "Urology",
];
