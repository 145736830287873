import React from 'react';
import { TextInput, Select, NumberInput } from '@mantine/core';

import styles from './index.module.css';
import classes from '../RequestForm/index.module.css';

const {input, label} = classes;

const inputStyles = {input: input, label: label};


const {
  inputContainer: containerClass,
  inputRow: inputRowClass,
  textbox: textboxClass,
  textarea: textareaClass,
  select: selectClass,
  btn: btnClass,
  addBtn: addBtnClass,
  removeBtn: removeBtnClass,
  rowSeperator: rowSeperatorClass,
  error: errorClass,
  errorMessage: errorMessageClass,
  controls: controlsClass,
  'inputContainer-grid': containerClassGrid,
} = styles;

const DynamicInput = ({
  inputRowList,
  setInputRowList,
  maxRows,
  addSeperator,
  containerStyles,
  inputRowStyles,
  textboxStyles,
  textareaStyles,
  selectStyles,
  addBtnStyles,
  removeBtnStyles,
  rowSeperatorStyles,
  errorStyles,
  errorMessageStyles,
  flexContainer = true
}) => {
  const onInputChangeHandler = (e, id) => {
    setInputRowList((inputRowList) => {
      const { name, value } = e.target;
      return inputRowList.map((inputRow) => {
        if (inputRow.id === id) {
          return {
            ...inputRow,
            [name]: { ...inputRow[name], value },
          };
        } else {
          return inputRow;
        }
      });
    });
  };

  const onValueChangeHandler = (id, name, value) => {
    setInputRowList((inputRowList) => {
      return inputRowList.map((inputRow) => {
        if (inputRow.id === id) {
          return {
            ...inputRow,
            [name]: { ...inputRow[name], value },
          };
        } else {
          return inputRow;
        }
      });
    });
  };

  const onAddClickHandler = () => {
    setInputRowList((inputRowList) => {
      if (inputRowList.length === maxRows) {
        return inputRowList;
      }
      const inputRow = {};

      for (let key in inputRowList[0]) {
        if (key === 'id') continue;
        inputRow[key] = { ...inputRowList[0][key], value: '', error: false };
      }

      if (inputRowList.length === 1) {
        inputRow.id = 2;
      } else {
        inputRow.id = inputRowList[inputRowList.length - 1].id + 1;
      }

      return inputRowList.concat(inputRow);
    });
  };

  const onDeleteClickHandler = (id) => {
    setInputRowList((inputRowList) => {
      return inputRowList.filter((inputRow) => inputRow.id !== id);
    });
  };

  return (
    <div className={flexContainer ? containerClass : containerClassGrid}>
      <button
            type="button"
            onClick={onAddClickHandler}
            className={[btnClass, addBtnClass].join(' ')}
            style={addBtnStyles}
        >
        </button>
      {inputRowList.map((inputRow, rowIndex) => {
        const { id } = inputRow;

        return (
          <React.Fragment key={id}>
            <div className={inputRowClass}>
              <div className={controlsClass}>
              {Object.keys(inputRow).map((key) => {
                if (key === 'id') return null;
                const {
                  type,
                  value,
                  options,
                  labelType,
                  required
                } = inputRow[key];
                let { label } = inputRow[key];

                if (labelType === 'incremental') {
                  label = `${label}${rowIndex + 1}`
                }

                if (type === 'input') {
                  return (
                    <div key={key}>
                      <TextInput
                        name={key}
                        label={label}
                        required={required}
                        classNames={inputStyles}
                        value={value}
                        onChange={(e) => onInputChangeHandler(e, id)}
                      />
                    </div>
                  );
                } else if (type === 'number') {
                  return (
                    <div key={key}>
                      <NumberInput
                        name={key}
                        label={label}
                        required={required}
                        classNames={inputStyles}
                        value={value}
                        onChange={(value) => onValueChangeHandler(id, key, value)}
                      />
                    </div>
                  );
                } else if (type === 'select') {
                  return (
                    <div key={key}>
                      <Select
                        name={key}
                        label={label}
                        required={required}
                        data={options}
                        classNames={inputStyles}
                        value={value}
                        onChange={(value) => {
                          onValueChangeHandler(id, key, value);
                        }}
                      />
                    </div>
                  );
                } else {
                  return null;
                }
              })}
              </div>
              {id === 1 ?
                null
                :
                (
                <button
                  type="button"
                  onClick={() => onDeleteClickHandler(id)}
                  className={[btnClass, removeBtnClass].join(' ')}
                  style={removeBtnStyles}
                >
                </button>
              )}
            </div>
            {addSeperator && (
              <div
                className={rowSeperatorClass}
                style={rowSeperatorStyles}
              ></div>
            )}
          </React.Fragment>
        );
      })}
    </div>
  );
};

export default DynamicInput;
