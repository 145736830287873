import {
  createStyles,
  Table,
  ScrollArea,
  UnstyledButton,
  Text,
  Center,
  Paper,
  Badge,
} from "@mantine/core";
import { Selector, ChevronDown, ChevronUp, Clock } from "tabler-icons-react";
import { useDispatch, useSelector } from "react-redux";
import { useState, useEffect } from "react";
import { getReferrals } from "../../API";
import { formatName, formatDate, getClient } from "../../utils";
import StatusBadge from "./StatusBadge";
import { useInterval } from "../../hooks";
import { setDownloadURL, setFromTable, setFormPage, setActiveReferral } from "./referralSlice";
import TableSkeleton from "./TableSkeleton";
import { OTHER } from "../../constants";
import { useNavigate } from "react-router-dom";

const useStyles = createStyles((theme) => ({
  th: {
    padding: "0 !important",
    fontFamily: "Roboto",
    color: "#8B8B8B",
    fontWeight: "bold",
  },
  row: {
    fontSize: "12px",
    fontFamily: "Open Sans",
    textAlign: "center",
  },
  textButton: {
    textAlign: "center",
    color: "#4A7CBD",
    fontWeight: "bold",
    fontSize: "12px",
    fontFamily: "Open Sans",
    "&:hover": { color: theme.fn.lighten("#4A7CBD", 0.05), cursor: "pointer" },
  },
  control: {
    width: "100%",
    padding: `${theme.spacing.xs}px ${theme.spacing.md}px`,

    "&:hover": {
      backgroundColor:
        theme.colorScheme === "dark"
          ? theme.colors.dark[6]
          : theme.colors.gray[0],
    },
  },

  icon: {
    width: 21,
    height: 21,
    borderRadius: 21,
  },
  scrollbar: {
    position: 'fixed !important',
    bottom: '6px !important',
    marginLeft: '1.5rem',
    marginRight: '1.75rem'
  },
}));

function Th({ children, reversed, sorted, onSort }) {
  const { classes } = useStyles();
  const Icon = sorted ? (reversed ? ChevronUp : ChevronDown) : Selector;
  return (
    <th className={classes.th}>
      <UnstyledButton onClick={onSort} className={classes.control}>
        <div style={{ display: "flex" }}>
          <Center style={{ flex: 1 }}>
            <Text
              style={{
                fontSize: "12px",
                color: "#A6A7AB",
                fontWeight: "bold",
                alignText: "center",
              }}
            >
              {children}
            </Text>
          </Center>
          <Center className={classes.icon}>
            <Icon size={14} />
          </Center>
        </div>
      </UnstyledButton>
    </th>
  );
}
const Row = ({ data, setModal }) => {
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const clientSite = getClient();
  const isAuthorizationEnabled = 0; //admin configurable

  let status = data.current_status;
  if (data.close_code) {
    if (data.close_code === "Consultation Complete") status = "eCARE Completed";
    else if (data.close_code === "In-Person Visit") {
      if (data.current_status === 'Fax Delivered' || data.current_status === 'Fax Failed') {
        status = data.current_status;
      } else {
        status = "Needs Completion";
      }
    } else if (data.close_code === "Video Visit") {
      if (data.current_status === 'Fax Delivered' || data.current_status === 'Fax Failed') {
        status = data.current_status;
      } else {
        status = "Needs Completion";
      }
    }
  }

  let action = "None";
  if (["Needs Completion", "Fax Delivered", "Fax Failed"].includes(status)) {
    const text = status === "Fax Delivered" ? "Refax Referral" : "Fax Referral";
    action = (
      <Text
        className={classes.textButton}
        onClick={() => {
          dispatch(setActiveReferral(data));
          dispatch(setDownloadURL(data.pdf_url));
          dispatch(setFromTable("true"));
          dispatch(setFormPage(0));
          setModal(true);
        }}
      >
        {text}
      </Text>
    );
  }
  if (isAuthorizationEnabled && data.close_code === "In-Person Visit") { //for MedHK
    action = (
      <Text
        className={classes.textButton}
        onClick={() => {
          navigate('/request-form', {state: { referralId: data.afya_id}});
        }}
      >
        Submit for Authorization
      </Text>
    );
  }

  let primaryCareProvider = data.pcp_name;
  let specialty = data.specialty;
  let appointment_date = '';

  if (clientSite.client === 'iehp') {
    if (data.appointment_date) {
      let aptDateTime = data.appointment_date.split(' ');
      let date = aptDateTime[0];
      let time = aptDateTime[1];

      if (date) {
        date = date.replaceAll('-', '/');
        appointment_date = date;
      }
      if (time) {
        appointment_date += ` ${time}`;
      }
    }
    primaryCareProvider = data.primary_care_provider;
    specialty = 'Post Discharge';

    if (status === 'Enrolled') {
      action = (
        <Text
          className={classes.textButton}
          onClick={() => {
            dispatch(setActiveReferral(data));
            setModal(true);
          }}
        >
          Update Referral
        </Text>
      );
    } else if (status === 'In Progress') {
      action = (
        <Text
          className={classes.textButton}
          onClick={() => {
            dispatch(setActiveReferral(data));
            setModal(true);
          }}
        >
          Submit Referral
        </Text>
      );
    }
  }

  const renderFiles = (docs) => {
    if (!docs || !docs.length) {
      return '';
    }
    return docs.map(doc => {
      return (
        <>
          <a href={doc.url} target="_blank" className={classes.textButton}>{doc.name}</a>
          <br/>
        </>
      );
    });
  };

  return (
    <tr key={data.afya_id}>
      <td className={classes.row}>
        <Badge
          size="lg"
          radius="xl"
          color="gray"
          style={{}}
          leftSection={
            <Center>
              <Clock size={15} strokeWidth={1.5} color={"gray"} />
            </Center>
          }
        >
          {data.creation_date}
        </Badge>
      </td>
      <td className={classes.row}>{data.patient_name}</td>
      <td className={classes.row} style={{ textAlign: "center" }}>
        {primaryCareProvider}
      </td>
      <td className={classes.row}>{specialty}</td>
      <td className={classes.row}>
        <StatusBadge status={status} />
      </td>
      <td className={classes.row}>{action}</td>
      {clientSite.client === 'iehp' ? (
        <>
          <td className={classes.row}>{appointment_date}</td>
          <td className={classes.row}>{data.scheduling_status}</td>
          <td className={classes.row}>{data.notes}</td>
          <td className={classes.row}>{renderFiles(data.docs)}</td>
          <td className={classes.row}>{data.medical_group_name}</td>
        </>
      ) : null}
    </tr>
  );
};

function filterData(data, search) {
  const keys = Object.keys(data[0]);
  const query = search.toLowerCase().trim();
  return data.filter((item) =>
    keys.some((key) => item[key].toLowerCase().includes(query))
  );
}

function sortData(data, payload) {
  if (!payload.sortBy) {
    return filterData(data, payload.search);
  }

  return filterData(
    [...data].sort((a, b) => {
      if (payload.reversed) {
        return b[payload.sortBy].localeCompare(a[payload.sortBy]);
      }

      return a[payload.sortBy].localeCompare(b[payload.sortBy]);
    }),
    payload.search
  );
}

export function TableSort({ setModal }) {
  const [sortedData, setSortedData] = useState([]);
  const [search, setSearch] = useState("");
  const [sortBy, setSortBy] = useState(null);
  const [reverseSortDirection, setReverseSortDirection] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const { token:auth_token, role } = useSelector((state) => state.auth);
  const specFilter = useSelector((state) => state.referral).specFilter;
  const clinicFilter = useSelector((state) => state.referral).clinicFilter;
  const patientFilter = useSelector((state) => state.referral).patientFilter;
  const PCPFilter = useSelector((state) => state.referral).PCPFilter;
  const statusFilter = useSelector((state) => state.referral).statusFilter;
  const { classes } = useStyles();
  const clientSite = getClient();

  const updateData = async () => {
    try {
      let referrals = await getReferrals(auth_token);
      referrals = referrals.filter(referral => {
        return referral.patient !== null;
      });
      let referralRows = referrals.map((obj) => {
        const pcp_name = obj.pcp
          ? formatName(obj.pcp.first_name, obj.pcp.last_name)
          : "";
        const patient_name = obj.patient
          ? formatName(obj.patient.first_name, obj.patient.last_name)
          : "";
        const close_code = obj.chat_session
          ? obj.chat_session.close_code?.close_code
          : null;
        return {
          pdf_url: obj.fax_url,
          afya_id: obj.afya_id,
          creation_date: formatDate(obj.createdAt),
          patient_name: patient_name,
          pcp_name: pcp_name,
          specialty: obj.specialty,
          current_status: obj.referral_status,
          action: "None",
          close_code,
          patient_first: obj.patient?.first_name,
          patient_last: obj.patient?.last_name,
          patient_dob: obj.patient.dob,
          patient_id: obj.patient_id,
          sex: obj.patient.gender === 0 ? "Female" : "Male",
          patient_member_id: obj.patient.member_identifier ? obj.patient.member_identifier : "",
          diagnosis: obj.diagnosis,
          symptoms: obj.symptoms_reason_question,
          pcp_npi: obj.pcp?.npi,
          pcp_id: obj.pcp_id,
          patient_mobile: obj.patient_mobile,
          patient_email: obj.patient_email,
          contact_time: obj.contact_time,
          coordinating_member: obj.coordinating_member,
          guardian: obj.guardian,
          staff: obj.staff,
          primary_care_provider: obj.primary_care_rovider,
          reason: obj.reason,
          docs: obj.docs,
          consentDocs: obj.consentDocs,
          appointment_date: obj.appointment_date,
          scheduling_status: obj.scheduling_status,
          notes: obj.notes,
          medical_group: obj.medical_group,
          patient_agora_id: obj.patient?.agora_id,
          reason_not_enrolling: obj.reason_not_enrolling || '',
          medical_group_name: obj.mdg?.name || ''
        };
      });

      if (clientSite.client === 'iehp') {
        referralRows = referralRows.filter(referral => {
          return (referral.current_status === 'Enrolled' || referral.current_status === 'In Progress');
        })
      }

      setSortedData(referralRows);
      setIsLoading(false);
    } catch (err) {
      console.error("Error while getting referrals", err);
    }
  };

  useEffect(() => {
    if (role !== OTHER) {
      setIsLoading(true);
      updateData();
    } else {
      setIsLoading(false);
    }
  }, [specFilter, clinicFilter, patientFilter, PCPFilter, role, statusFilter]);


  useInterval(updateData, 120000, role !== OTHER)

  const setSorting = (field) => {
    const reversed = field === sortBy ? !reverseSortDirection : false;
    setReverseSortDirection(reversed);
    setSortBy(field);
    setSortedData(sortData(sortedData, { sortBy: field, reversed, search }));
  };

  const handleSearchChange = (event) => {
    const { value } = event.currentTarget;
    setSearch(value);
    setSortedData(
      sortData(sortedData, {
        sortBy,
        reversed: reverseSortDirection,
        search: value,
      })
    );
  };

  const rows = sortedData.map((rowData, idx) => (
    <Row data={rowData} setModal={setModal} key={idx} />
  ));

  let styles = {
    paperPadding: '30px',
    height: 'auto',
    overflowY: 'visible',
    tableWidth: '700',
    col1Width: '13%',
    col2Width: '17%',
    col3Width: '20%',
    col4Width: '13%',
    col5Width: '17%',
    col6Width: '10%',
    col7Width: '',
    col8Width: '',
    col9Width: '',
    col10Width: '',
    col11Width: '',
  };

  if (clientSite.client === 'iehp') {
    styles = {
      paperPadding: '30px 8px',
      overflowY: 'scroll',
      height: '28.5rem',
      tableWidth: '125vw',
      col1Width: '10%',
      col2Width: '10%',
      col3Width: '15%',
      col4Width: '10%',
      col5Width: '12%',
      col6Width: '10%',
      col7Width: '13%',
      col8Width: '18%',
      col9Width: '16%',
      col10Width: '18%',
      col11Width: '16%',
    };
  }

  return (
    <Paper
      withBorder
      shadow="md"
      p={30}
      mt={0}
      radius="md"
      position="center"
      style={{ width: "100%", padding: styles.paperPadding, height: styles.height, overflowY: styles.overflowY}}
    >
      <ScrollArea classNames={{scrollbar: classes.scrollbar}}>
        <Table
          horizontalSpacing="md"
          verticalSpacing="md"
          highlightOnHover
          sx={{ tableLayout: "fixed", minWidth: 700, width: styles.tableWidth }}
        >
          <colgroup>
            <col style={{ width: styles.col1Width }} />
            <col style={{ width: styles.col2Width }} />
            <col style={{ width: styles.col3Width }} />
            <col style={{ width: styles.col4Width }} />
            <col style={{ width: styles.col5Width }} />
            <col style={{ width: styles.col6Width }} />
            {clientSite.client === 'iehp' ? (
              <>
                <col style={{ width: styles.col7Width }} />
                <col style={{ width: styles.col8Width }} />
                <col style={{ width: styles.col9Width }} />
                <col style={{ width: styles.col10Width }} />
                <col style={{ width: styles.col11Width }} />
              </>
            ): null}
          </colgroup>
          <thead>
            <tr>
              <Th
                className={classes.colHead}
                key={"creation_date"}
                sorted={sortBy === "creation_date"}
                reversed={reverseSortDirection}
                onSort={() => setSorting("creation_date")}
              >
                CREATION DATE
              </Th>
              <Th
                key={"patient_name"}
                sorted={sortBy === "patient_name"}
                reversed={reverseSortDirection}
                onSort={() => setSorting("patient_name")}
              >
                PATIENT
              </Th>
              <Th
                key={"pcp_name"}
                sorted={sortBy === "pcp_name"}
                reversed={reverseSortDirection}
                onSort={() => setSorting("pcp_name")}
              >
                PRIMARY CARE PROVIDER
              </Th>
              <Th
                key={"specialty"}
                sorted={sortBy === "specialty"}
                reversed={reverseSortDirection}
                onSort={() => setSorting("specialty")}
              >
                SPECIALTY
              </Th>
              <Th
                key={"current_status"}
                sorted={sortBy === "current_status"}
                reversed={reverseSortDirection}
                onSort={() => setSorting("current_status")}
              >
                CURRENT STATUS
              </Th>
              <Th
                key={"action"}
                sorted={sortBy === "action"}
                reversed={reverseSortDirection}
                onSort={() => setSorting("action")}
              >
                ACTION
              </Th>
              {clientSite.client === 'iehp' ? (
                <>
                  <Th
                    reversed={reverseSortDirection}
                  >
                  Appointment Date/Time
                  </Th>
                  <Th
                    reversed={reverseSortDirection}
                  >
                    Scheduling Status
                  </Th>
                  <Th
                    reversed={reverseSortDirection}
                  >
                    Notes
                  </Th>
                  <Th
                    reversed={reverseSortDirection}
                  >
                    Files
                  </Th>
                  <Th
                    reversed={reverseSortDirection}
                  >
                    Medical Group
                  </Th>
                </>
              ) : null}
            </tr>
          </thead>
          {isLoading ? (
            <TableSkeleton />
          ) : (
            <tbody>
              {rows.length > 0 ? (
                rows
              ) : (
                <tr>
                  <td colSpan={clientSite.client === 'iehp' ? 8 : 6}>
                    <Text weight={500} align="center">
                      Nothing found
                    </Text>
                  </td>
                </tr>
              )}
            </tbody>
          )}
        </Table>
      </ScrollArea>
    </Paper>
  );
}
