import React, { useEffect, useState } from "react";
import { createStyles } from "@mantine/core";
import { useForm, yupResolver } from "@mantine/form";
import { useLocation, Navigate } from "react-router-dom";
import * as Yup from "yup";

import { getReferral } from "../../API/referrals";
import { MemberProvider, Service, Description, ICD, CPT, CommentsAndFileUpload } from './Components';
import DynamicInput from "../DynamicInput";

const schema = Yup.object().shape({});

const useStyles = createStyles((theme) => ({
  container: {
    display: 'flex',
    width: '90%',
    margin: '0 auto',
    marginTop: '1rem',
    marginBottom: '2rem',
    backgroundColor: '#FFF',
    padding: '0 2rem',
    paddingTop: '2rem',
  },
  rectangle: {
    backgroundColor: '#D9D9D9',
    width: '263px',
  },
  formContainer: {
    flexGrow: '1',
    marginLeft: '2rem'
  },
  formTitle: {
    width: '201px',
    height: '22px',
    fontSize: '16px',
    fontWeight: '700',
    lineHeight: '21.86px'
  },
  btn: {
    background: '#2A31A4',
    color: '#FFF',
    width: '142px',
    height: '52px',
    borderRadius: '10px',
    marginTop: '1rem',
    cursor: 'pointer'
  }
}));

const RequestForm = () => {
  const location = useLocation();

  const [isPageLoading, setIsPageLoading] = useState(true);
  const [referralId, setReferralId] = useState(null);
  const [isDataLoading, setIsDataLoading] = useState(false);
  const [referral, setReferral] = useState(null);
  const [isNewReferral, setIsNewReferral] = useState(false);
  const { classes } = useStyles();

  const [icdRowList, setICDRowList] = useState([
    {
      id: 1,
      icd: {
        type: 'input',
        value: '',
        placeholder: '',
        label: 'ICD',
        labelType: 'incremental',
        required: true
      },
    },
  ]);

  const [cptRowList, setCPTRowList] = useState([
    {
      id: 1,
      cpt: {
        type: 'input',
        value: '',
        label: 'CPT',
        labelType: 'incremental',
        required: true
      },
      modifier: {
        type: 'select',
        value: '',
        label: 'Modifier',
        options: [
          { label: 'India', value: 'india' },
          { label: 'Country 2', value: 'country2' },
          { label: 'Country 3', value: 'country3' },
        ],

      },
      qty: {
        type: 'number',
        value: '',
        label: 'Qty (numeric only)',
        required: true,
      },
    },
  ]);


  const getValues = (type) => {
    const values = type.map((inputRow) => {
      const inputRowValues = {};
      for (let key in inputRow) {
        if (key === 'id') continue;
        inputRowValues[key] = inputRow[key].value;
      }
      return inputRowValues;
    });
    console.log(values)
    return values;
  };

  const form = useForm({
    schema: yupResolver(schema),
    initialValues: {},
  });

  useEffect(() => {
    setReferralId(location?.state?.referralId);
    setIsPageLoading(false);
  }, [location]);

  useEffect(() => {
    const fetchReferral = async () => {
      setIsDataLoading(true);
      try {
        const referral = await getReferral(referralId);
        setReferral(referral);
      } catch (error) {
      }
      setIsDataLoading(false);
    };

    if (referralId) {
      fetchReferral();
    }
  }, [referralId]);

  if (!isPageLoading && !referralId) {
    setIsNewReferral(true);
    // return <Navigate replace to='/' />
  }

  return (
    <div className={classes.container}>
      <aside className={classes.rectangle}></aside>
      <main className={classes.formContainer}>
        <header className={classes.header}>
          <span className={classes.formTitle}>REFERRAL REQUEST FORM</span>
          <Description />
        </header>
        <form>
          <MemberProvider form={form}/>
          <Service form={form}/>
          <ICD>
            <DynamicInput
              inputRowList={icdRowList}
              setInputRowList={setICDRowList}
              flexContainer={false}
              maxRows={12}
            />
          </ICD>
          <CPT>
            <DynamicInput
                inputRowList={cptRowList}
                setInputRowList={setCPTRowList}
                maxRows={25}
            />
          </CPT>
          {/* <button type="button" onClick={() => {getValues(icdRowList)}}>get values</button> */}
          {/* <button type="button" onClick={() => {getValues(cptRowList)}}>get values</button> */}
          <CommentsAndFileUpload />
          <div style={{textAlign: 'center', marginBottom: '1rem'}}>
              <button type='reset' className={classes.btn}>CANCEL</button>
              <button type='submit' className={classes.btn} style={{marginLeft: '1rem'}}>SUBMIT</button>
          </div>
        </form>
      </main>
    </div>
  )
}

export default RequestForm;