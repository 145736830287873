import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  user: { first_name: null, last_name: null, email: null, uid: null },
  token: null,
  loginEmail: null,
  password: null,
  org_id: null,
  role: null,
  medical_group: null
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setCredentials: (state, action) => {
      const { loginEmail, password } = action.payload;
      state.loginEmail = loginEmail;
      state.password = password;
    },
    setUser: (state, action) => {
      const { token, first_name, last_name, email, user_id, uid } = action.payload;
      const userString = JSON.stringify({
        token,
        first_name,
        last_name,
        email,
        uid: user_id || uid,
      });
      sessionStorage.setItem("afya_user", userString);
      state.user = { first_name, last_name, email, uid: user_id || uid };
      state.token = token;
    },
    clearUser: (state, action) => {
      sessionStorage.removeItem("afya_user");
      sessionStorage.removeItem("userOrg");
      sessionStorage.removeItem("userRole");
      sessionStorage.removeItem("userMedicalGroup");
      state.user = {
        first_name: null,
        last_name: null,
        email: null,
        uid: null,
      };
      state.token = null;
      state.org_id = null;
      state.role = null;
      state.medical_group = null;
    },
    setOrg: (state, { payload }) => {
      state.org_id = payload;
      sessionStorage.setItem("userOrg", payload);
    },
    setRole: (state, { payload }) => {
      state.role = payload;
      sessionStorage.setItem("userRole", payload);
    },
    setMedicalGroup: (state, { payload }) => {
      state.medical_group = payload;
      sessionStorage.setItem("userMedicalGroup", payload);
    },
  },
});

export const { setCredentials, setUser, clearUser, setOrg, setRole, setMedicalGroup } = authSlice.actions;
export default authSlice.reducer;
export const selectCurrentUser = (state) => state.auth.user;
