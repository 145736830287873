import { TextInput, Select, createStyles } from '@mantine/core';

import styles from '../../index.module.css';

const {section, sectionTitle, inputRow, input, label, divider} = styles;

const useStyles = createStyles((theme) => ({
    question: {
        '& label': {
            display: 'inline-block',
            width: '228px',
        },
        '& span': {
            color: '#f03e3e'
        },
        '& button': {
            width: '103px',
            height: '55px',
            borderRadius: '14px',
            cursor: 'not-allowed',
            marginTop: '0.5rem',
            marginRight: '0.75rem',
        }
    }
}));

const inputStyles = {input: input, label: label};

const MemberProvider = ({form}) => {
  const { classes } = useStyles();

  return (
    <>
        <section className={section}>
            <h3 className={sectionTitle}>Member/Provider Identification</h3>
            <div className={inputRow}>
                <TextInput
                    label="IEHP ID"
                    required
                    classNames={inputStyles}
                    styles={{input: {marginTop: '1rem'}}}
                    // {...form.getInputProps("iehp_id")}
                />
                <div className={classes.question}>
                    <label className={label}>
                        Are you submitting a correction to an existing referral?
                        <span> *</span>
                    </label>
                    <button type='button' disabled className={classes.btn}>Yes</button>
                    <button type='button' disabled className={classes.btn} style={{background: '#21519B', color: '#FFF'}}>No</button>
                </div>
                <Select
                    label="Requesting Provider"
                    required
                    classNames={inputStyles}
                    styles={{input: {marginTop: '1rem'}}}
                    data={[]}
                />
            </div>
            <div className={divider}></div>
        </section>
        <section className={section}>
            <h3 className={sectionTitle}>Member Information</h3>
            <div className={inputRow}>
                <TextInput
                    label="Name"
                    classNames={inputStyles}
                    // {...form.getInputProps("name")}
                />
                <TextInput
                    label="Gender"
                    classNames={inputStyles}
                />
                <TextInput
                    label="DOB"
                    classNames={inputStyles}
                />
            </div>
            <div className={inputRow}>
                <TextInput
                    label="Address"
                    classNames={inputStyles}
                />
                <TextInput
                    label="City"
                    classNames={inputStyles}
                />
                <TextInput
                    label="State-Zip"
                    classNames={inputStyles}
                />
            </div>
            <div className={inputRow}>
                <TextInput
                    label="Phone"
                    classNames={inputStyles}
                />
                <TextInput
                    label="IEHP ID"
                    classNames={inputStyles}
                />
                <TextInput
                    label="CIN"
                    classNames={inputStyles}
                />
            </div>
            <div className={inputRow}>
                <TextInput
                    label="Medicare ID"
                    classNames={inputStyles}
                />
                <TextInput
                    label="Medi-Cal"
                    classNames={inputStyles}
                />
                <TextInput
                    label="LOB"
                    classNames={inputStyles}
                />
            </div>
            <div className={inputRow}>
                <TextInput
                    label="County"
                    classNames={inputStyles}
                />
                <TextInput
                    label="Aid Code"
                    classNames={inputStyles}
                />
                <TextInput
                    label="Group"
                    classNames={inputStyles}
                />
            </div>
            <div className={divider}></div>
        </section>
        <section className={section}>
            <h3 className={sectionTitle}>Requesting Provider Information</h3>
            <div className={inputRow}>
                <TextInput
                    label="Name"
                    classNames={inputStyles}
                />
                <TextInput
                    label="NPI#"
                    classNames={inputStyles}
                />
                <TextInput
                    label="Phone"
                    classNames={inputStyles}
                />
            </div>
            <div className={inputRow}>
                <TextInput
                    label="Fax#"
                    classNames={inputStyles}
                />
                <TextInput
                    label="Address"
                    classNames={inputStyles}
                />
                <TextInput
                    label="City"
                    classNames={inputStyles}
                />
            </div>
            <div className={inputRow}>
                <TextInput
                    label="State"
                    classNames={inputStyles}
                />
                <TextInput
                    label="ZIP"
                    classNames={inputStyles}
                />
                <TextInput
                    label="Request Date"
                    classNames={inputStyles}
                />
            </div>
            <div className={inputRow}>
                <TextInput
                    label="Provider Signature"
                    classNames={inputStyles}
                />
            </div>
            <div className={divider}></div>
        </section>
    </>
  )
}

export default MemberProvider;