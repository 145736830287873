import { createStyles } from "@mantine/core";

const useStyles = createStyles((theme) => ({
    formDescription: {
      backgroundColor: '#F9F8FC',
      width: '995px',
      marginTop: '1rem',
      padding: '2rem',

      '& p': {
        fontWeight: '600',
        fontSize: '16px',
        lineHeight: '24px',
        margin: '0'
      },

      '& a': {
        textDecoration: 'none'
      }
    },
    boxDescription: {
        border: '2px solid #EAEAEA',
        padding: '0.5rem',
        marginTop: '1rem',

        '& p': {
            color: '#870A30'
        }
    }
}));

const Description = () => {
  const { classes } = useStyles();

  return (
    <div className={classes.formDescription}>
        <p>
            UPON ACCEPTANCE OF REFERRAL AND TREATMENT OF THE MEMBER, THE PHYSICIAN/PROVIDER AGREES TO ACCEPT IEHP CONTRACTED RATES. This referral verifies medical necessity only. Payments for services are dependent upon the member's eligibility at the time services are rendered.
        </p>
        <br />
        <p>No referral required for OB/GYN services. For more information click: <a href="https://ewebapp.iehp.org/ProviderPortal/" target="_blank">Open access OB/GYN services.</a></p>
        <div className={classes.boxDescription}>
            <p>Do not submit referral requests for services not requiring prior authorization. Examples include, but are not limited to, preventive screenings, Biomarker testing for Members with advanced or metastatic stage 3 or 4 cancers, family planning, HIV testing and counseling.</p>
            <br />
            <p>Referrals received for these services will be cancelled.</p>
            <br />
            <p>Be sure to schedule the Member for needed services without delay.</p>
            <br />
            <p>For a complete list services not requiring prior authorization, please visit:</p>
            <a href="https://files.medi-cal.ca.gov/pubsdoco/publications/masters-mtp/part2/prev.pdf" target="_blank">https://files.medi-cal.ca.gov/pubsdoco/publications/masters-mtp/part2/prev.pdf</a>
            <br />
            <br />
            <p>To reference Provider Manual Policy 14D, Pre-Service Referral Authorization Process, please refer to the <a href="https://www.iehp.org/en/providers/provider-manuals?target=2022manuals" target="_blank">Provider Manuals</a>.</p>
        </div>
    </div>
  )
}

export default Description;