import { store } from "../store";
import { clearUser } from "../components/RequireAuth/authSlice";
import axios from "axios";

const backend = axios.create({});

backend.interceptors.response.use(
  (response) => response,
  async (error) => {
    const status = error.response.status;
    if (status === 401) {
      store.dispatch(clearUser());
      return;
    }
    return Promise.reject(error);
  }
);

export default backend;
