import {
  Text,
  Title,
  Button,
  Group,
  Textarea,
  createStyles,
  Center,
} from "@mantine/core";
import eCAREGraphic from "../../assets/ecare-graphic.svg";
import { useSelector, useDispatch } from "react-redux";
import { useState } from "react";
import { resetFormData } from "./referralSlice";
import { updateStatus, createChatSession } from "../../API";
import { createNotification } from "../Notifications";
const useStyles = createStyles((theme, _params, getRef) => ({
  button: {
    backgroundColor: "#E17A3D",
    height: "40px",
    borderRadius: "10px",
    "&:hover": {
      backgroundColor: theme.fn.darken("#E17A3D", 0.05),
    },
  },
  textArea: { width: "230px", height: "400px" },
}));

const Redirect = ({ setModal }) => {
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const { symptom_reason_question, pcp_id, pcp_agora_id } = useSelector(
    (state) => state.referral
  ).providerInfo;
  const initialText = "Symptoms: " + symptom_reason_question;
  const [clinicalQuestion, setClinicalQuestion] = useState(initialText);
  const [isLoading, setIsLoading] = useState(false);
  const { patient_id, patient_agora_id, master_spec_id } = useSelector(
    (state) => state.referral
  ).patientInfo;
  const { diagnosis } = useSelector(
    (state) => state.referral
  ).providerInfo;
  const afya_id = useSelector((state) => state.referral).currentAfyaId;
  const auth_token = useSelector((state) => state.auth).token;
  return (
    <div style={{ display: "flex", flexDirection: "row" }}>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          padding: "20px",
          width: "330px",
          alignItems: "center",
          justifyContent: "center",
          height: "470px",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            height: "75%",
            width: "300px",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "25px",
          }}
        >
          <img
            src={eCAREGraphic}
            alt="eCARE graphic"
            style={{ height: "240px" }}
          />
          <Title
            style={{ fontSize: "18px", color: "#4E7FC2", marginTop: "10px" }}
          >
            Specialty is Live in eCARE Access!
          </Title>
          <Text
            style={{
              textAlign: "center",
              color: "#495057",
              fontSize: "15px",
              marginTop: "10px",
            }}
          >
            Now primary care providers can communicate using eCARE messages with
            a virtual specialist who will respond within 24 hours. Once a chat
            is completed, it may be necessary to complete it as a referral.
          </Text>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Textarea
          className={classes.textArea}
          label="Clinical Question"
          required
          minRows={15}
          maxRows={15}
          value={clinicalQuestion}
          onChange={(event) => {
            setClinicalQuestion(event.target.value);
          }}
        />
        <Center>
          <Button
            className={classes.button}
            loading={isLoading}
            onClick={async () => {
              setIsLoading(true);
              try {
                await createChatSession(
                  diagnosis[0],
                  patient_agora_id,
                  pcp_agora_id,
                  clinicalQuestion,
                  pcp_id,
                  patient_id,
                  master_spec_id,
                  afya_id,
                  auth_token
                );
                await updateStatus(afya_id, auth_token);
                createNotification(
                  "Success!",
                  "Chat session created successfully",
                  "SUCCESS"
                );
              } catch (error) {
                console.error("Error creating chat session", error);
              }
              setIsLoading(false);
              dispatch(resetFormData());
              setModal(false);
            }}
          >
            Create eCARE Chat
          </Button>
        </Center>
      </div>
    </div>
  );
};
export default Redirect;
