import { Select } from "@mantine/core";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { setPCPFilter } from "./referralSlice";
import { findReferralUsers } from "../../API";
import { useState } from "react";
import usefilterStyles from "./filterStyles";

const PCPSelect = () => {
  const PCPFilter = useSelector((state) => state.referral).PCPFilter;
  const dispatch = useDispatch();
  const [results, setResults] = useState([]);
  const { classes } = usefilterStyles();

  return (
    <Select
      className={classes.filterStyles}
      label="Primary Care Provider"
      data={results}
      searchable
      clearable
      value={PCPFilter}
      onSearchChange={async (input) => {
        try {
          const data = await findReferralUsers(input, "pcp");
          data.unshift({ value: "All", label: "All" });
          setResults(data);
        } catch (e) {
          console.error(e);
        }
      }}
      onChange={(value) => {
        dispatch(setPCPFilter(value));
      }}
    />
  );
};

export default PCPSelect;
