import moment from 'moment'

import { api_url, base_url } from "../config";
import { capitalizeFirst, formatName, getDob } from "../utils";
import { createNotification } from "../components/Notifications";
import { store } from "../store";
import backend from "./backendInstance";
import { capitalizeWords } from "../utils";
import { STAFF_SUBMITTER } from "../constants";
import { createChat } from './index';

export const createReferral = async (form_data, token) => {
  const state = store.getState();
  const { org_id, medical_group } = state.auth;

  const config = {
    method: "POST",
    url: `${api_url}/create_referral`,
    data: {
      patient_id: form_data.patient_id,
      hpsj_patient_id: form_data.member_id,
      patient_first: form_data.patient_first,
      patient_last: form_data.patient_last,
      patient_dob: form_data.dob,
      patient_plan: form_data.patient_plan || '',
      hpsj_select: form_data.hpsj_select || '',
      hpsj_plan_type: form_data.plan_type || '',
      diagnosis: form_data.diagnosis || [''],
      symptoms_reason_question: form_data.symptom_reason_question,
      servicing_provider: form_data.servicing_provider,
      pcp: form_data.pcp,
      pcp_npi: form_data.pcp_npi,
      pcp_id: form_data.pcp_id,
      specialty: form_data.specialty,
      order_id: form_data.order_id,
      patient_mobile: form_data.patient_mobile, //here onwards IEHP data
      patient_email: form_data.patient_email,
      contact_time: form_data.contact_time,
      coordinating_member: form_data.coordinating_member,
      guardian: form_data.guardian,
      staff: form_data.staff,
      primary_care_rovider: form_data.primary_care_provider,
      reason: form_data.reason,
      referral_status: form_data.referral_status,
      consentDocs: form_data.consentDocs,
      reason_not_enrolling: form_data.reason_not_enrolling,
      docs: form_data.docs, //upto here IEHP data
      org_id,
      medical_group,
    },
    headers: { auth: token },
  };
  try {
    const result = await backend.request(config);
    return result.data;
  } catch (error) {
    console.error("error in CreateReferral", error);
    throw error;
  }
};

export const updateReferral = async (form_data, token) => {
  const state = store.getState();
  const { org_id, medical_group } = state.auth;

  const config = {
    method: "POST",
    url: `${api_url}/update_vrp_referral`,
    data: {
      patient_id: form_data.patient_id,
      hpsj_patient_id: form_data.member_id,
      patient_first: form_data.patient_first,
      patient_last: form_data.patient_last,
      patient_dob: form_data.dob,
      patient_plan: form_data.patient_plan || '',
      hpsj_select: form_data.hpsj_select || '',
      hpsj_plan_type: form_data.plan_type || '',
      diagnosis: form_data.diagnosis || [''],
      symptoms_reason_question: form_data.symptom_reason_question,
      servicing_provider: form_data.servicing_provider,
      pcp: form_data.pcp,
      pcp_npi: form_data.pcp_npi,
      pcp_id: form_data.pcp_id,
      specialty: form_data.specialty,
      order_id: form_data.order_id,
      org_id,
      medical_group,
      afya_id: form_data.afya_id,
      patient_mobile: form_data.patient_mobile, //here onwards IEHP data
      patient_email: form_data.patient_email,
      contact_time: form_data.contact_time,
      coordinating_member: form_data.coordinating_member,
      guardian: form_data.guardian,
      staff: form_data.staff,
      primary_care_rovider: form_data.primary_care_provider,
      reason: form_data.reason,
      referral_status: form_data.referral_status,
      appointment_date: form_data.appointment_date,
      notes: form_data.notes,
      scheduling_status: form_data.scheduling_status,
      consentDocs: form_data.consentDocs,
      reason_not_enrolling: form_data.reason_not_enrolling,
      docs: form_data.docs, //upto here IEHP data
    },
    headers: { auth: token },
  };
  try {
    const result = await backend.request(config);
    return result.data;
  } catch (error) {
    console.error("error in updateReferral", error);
    throw error;
  }
};

export const findPCPs = async (input, token) => {
  const state = store.getState();
  const { org_id } = state.auth;
  const config = {
    method: "POST",
    url: `${api_url}/search_providers`,
    data: {
      input: input,
      org_id: org_id,
      role_name: "Submitter Provider",
    },
    headers: { "Content-Type": "application/json", auth: token },
  };
  try {
    const res = await backend.request(config);
    return res.data.map((item) => ({
      label: formatName(item.first_name, item.last_name),
      value: JSON.stringify(item),
      ...item,
    }));
  } catch (error) {
    console.error("error in FindPCPs", error);
    return [];
  }
};

export const getReferrals = async (token) => {
  const state = store.getState();
  const { org_id, role, medical_group } = state.auth;
  const { specFilter, statusFilter, clinicFilter, patientFilter, PCPFilter } =
    state.referral;
  let close_codes = null;
  let referral_status = null;
  if (statusFilter === "All") referral_status = null;
  else if (statusFilter === "Needs Completion") {
    close_codes = ["In-Person Visit", "Video Visit"];
  } else if (statusFilter === "eCARE Completed") {
    close_codes = ["Consultation Complete"];
  } else {
    referral_status = statusFilter;
  }
  const config = {
    method: "POST",
    url: `${api_url}/referrals`,
    data: {
      medical_group: clinicFilter === "All" ? null : clinicFilter,
      specialty: specFilter === "All" ? null : specFilter,
      pcp_id: PCPFilter === "All" ? null : PCPFilter,
      patient_id: patientFilter === "All" ? null : patientFilter,
      referral_status,
      close_codes,
      org_id,
      role
    },
    headers: { auth: token },
  };

  if (role === STAFF_SUBMITTER) {
    config.data.medical_group = medical_group;
  }

  try {
    const result = await backend.request(config);
    return result.data;
  } catch (error) {
    console.error("error in getReferrals", error);
  }
};

export const getReferral = async (afya_id) => {
  const auth_token = store.getState().auth.token;
  const config = {
    method: "POST",
    url: `${api_url}/ref_get`,
    data: { afya_id },
    headers: { auth: auth_token },
  };
  try {
    const result = await backend.request(config);
    return result.data;
  } catch (error) {
    console.error("error in getReferral", error);
    createNotification(
      "Something went wrong",
      "Referral could not be fetched"
    );
  }
};

export const sendFax = (fax_number, media_url, auth_token) => {
  const config = {
    method: "POST",
    url: `${api_url}/send_fax`,
    data: { fax_number, media_url },
    headers: { auth: auth_token },
  };
  try {
    const result = backend.request(config);
    return result.data;
  } catch (error) {
    console.error("error in sendFax", error);
  }
};

export const findSpec = async (input, token) => {
  const state = store.getState();
  const { org_id } = state.auth;
  const config = {
    method: "POST",
    url: `${api_url}/search_specialties`,
    data: {
      input: input,
      org_id: org_id,
    },
    headers: { auth: token },
  };
  try {
    const res = await backend.request(config);
    return res.data.map((item) => ({
      label: item.name,
      value: JSON.stringify(item),
    }));
  } catch (error) {
    console.error("error in findSpec", error);
    return [];
  }
};

export const updateStatus = async (afya_id, token) => {
  const config = {
    method: "POST",
    url: `${api_url}/update_status`,
    data: { afya_id, status: "eCARE In Progress" },
    headers: { auth: token },
  };
  try {
    await backend.request(config);
  } catch (error) {
    console.error("error in updateStatus", error);
    createNotification(
      "Something went wrong",
      "Referral status could not be updated"
    );
  }
};

export const getAllSpecs = async () => {
  const auth_token = store.getState().auth.token;
  const config = {
    method: "GET",
    url: `${api_url}/referral_specs`,
    headers: { auth: auth_token },
  };
  try {
    const result = await backend.request(config);
    return result.data;
  } catch (error) {
    createNotification(
      "Something went wrong",
      "Could not get specialties list"
    );
    console.error("error in getSpecOptions", error);
    return [];
  }
};

export const getAllClinics = async () => {
  const auth_token = store.getState().auth.token;
  const config = {
    method: "GET",
    url: `${api_url}/referral_clinics`,
    headers: { auth: auth_token },
  };
  try {
    const result = await backend.request(config);
    return result.data.map((clinic) => ({
      value: clinic,
      label: capitalizeWords(clinic),
    }));
  } catch (error) {
    createNotification("Something went wrong", "Could not get clinics list");
    console.error("error in getSpecOptions", error);
    return [];
  }
};

export const findReferralUsers = async (input, type) => {
  const auth_token = store.getState().auth.token;
  const config = {
    method: "POST",
    url: `${api_url}/search_referral_users`,
    data: {
      input: input,
      type: type,
    },
    headers: { auth: auth_token },
  };
  try {
    const result = await backend.request(config);
    return result.data.map((item) => ({
      label: item.name,
      value: item.id,
    }));
  } catch (error) {
    console.error("error in findReferralUsers", error);
    return [];
  }
};

export const isDoctorAvailable = async (requestBody) => {
  const auth_token = store.getState().auth.token;
  const config = {
    method: "POST",
    url: `${api_url}/search_provider_in_org`,
    data: requestBody,
    headers: { auth: auth_token },
  };
  try {
    const resp = await backend.request(config);
    return resp.data;
  } catch (error) {
    console.error("error in isDoctorAvailable", error);
    return { isdoctor: 0 };
  }
};

export const sendSMS = async (mobile) => {
  const auth_token = store.getState().auth.token;
  const config = {
    method: "POST",
    url: `${api_url}/send_sms`,
    data: { mobile },
    headers: { auth: auth_token },
  };
  try {
    await backend.request(config);
  } catch (error) {
    console.error("error in sendSMS", error);
    createNotification(
      "Something went wrong",
      "Could not send SMS"
    );
  }
};

export const sendEmail = async (email) => {
  const auth_token = store.getState().auth.token;
  const config = {
    method: "POST",
    url: `${api_url}/send_email`,
    data: { email },
    headers: { auth: auth_token },
  };
  try {
    await backend.request(config);
  } catch (error) {
    console.error("error in sendEmail", error);
    createNotification(
      "Something went wrong",
      "Could not send Email"
    );
  }
};

export const getStaffSubmitters = async (medical_group) => {
  const auth_token = store.getState().auth.token;
  const config = {
    method: "POST",
    url: `${api_url}/get_sub_staff_of_med_group`,
    data: { med_grp: medical_group },
    headers: { auth: auth_token },
  };
  try {
    const result = await backend.request(config);
    return result.data;
  } catch (error) {
    console.error("error in getStaffSubmitters", error);
    createNotification(
      "Something went wrong",
      "Staff submitters could not be fetched"
    );
  }
};

export const createSession = async (session_data) => {
  const env = process.env.REACT_APP_ENV;
  let owner2 = {
    user_id: 'fa54d610-0c7a-11ec-9f1e-f1db7f4f025c',
    agora_id: '29558566'
  };

  if (env === 'uat') {
    owner2 = {
      user_id: 'c0861310-9324-11eb-8528-e11b72b6787f',
      agora_id: '12862108'
    };
  } else if (env === 'prod') {
    owner2 = {
      user_id: '69ac0930-a460-11eb-9e87-b7e26e854f9f',
      agora_id: '61272458'
    };
  }

  const auth_token = store.getState().auth.token;
  const config = {
    method: "POST",
    url: `${api_url}/create_custom_session`,
    data: {
      patient_id: session_data.patient_id,
      patient_agora_id: session_data.patient_agora_id,
      owner_id_1: session_data.owner1_id,
      owner_id_2: owner2.user_id,
      specialty: 'c1db0ec0-6fc6-11ed-a2b5-1dec9792ef22',
      members: [session_data.owner1_id, owner2.user_id, session_data.patient_id],
      responders: [],
      agora_ids: [session_data.owner1_agora_id, owner2.agora_id, session_data.patient_agora_id],
      status: 1,
      permissions: '123e4567-e89b-12d3-a456-426655440000',
      updatedTime: new Date(),
      org_id: session_data.org_id,
      medical_group: session_data.medical_group,
    },
    headers: { auth: auth_token },
  };
  try {
    const session = await backend.request(config);
    await backend.request({
      method: "POST",
      url: `${api_url}/update_session_id`,
      data: { session_id: session.data.id, afya_id: session_data.afya_id },
      headers: { auth: auth_token },
    });

    const { data } = await backend.request({
      method: "POST",
      url: `${base_url}/chat/session_details`,
      data: {
        session_id: session.data.id
      },
      headers: {
        auth: auth_token
      }
    });

    const request_payload = {
      from_id: data['owner1.user_id'],
      from_name: `${data['owner1.first_name']} ${data['owner1.last_name']}`,
      img_url: data['owner1.image_url'],
      link: "",
      session_id: data.id,
      speciality: data['spec_master.name'],
      time: moment().utc().format("YYYY-MM-DD[T]HH:mm:ss.SSS[Z]"),
      updatedTime: moment().format('MMM-DD-YYYY, hh:mm A'),
    };

    const message1 = `This chat has been assigned to ${data['owner2.first_name']} ${data['owner2.last_name']} (Post Discharge) and the provider will respond to this chat after they've reviewed`;
    const message2 = `RE:${formatName(data['patient.first_name'], data['patient.last_name'])}, ${data['patient.gender'] === 0 ? 'Female' : 'Male'},  ${getDob(data['patient.dob']) + ' ' + 'Years Old'}, ${capitalizeFirst(data.clinical_question)}`;

    request_payload.datatype = 'NOTES';
    request_payload.message = message1;
    await createChat(request_payload, auth_token);

    request_payload.datatype = 'TEXT';
    request_payload.message = message2;
    await createChat(request_payload, auth_token);
  } catch (error) {
    console.error("error in createSession", error);
    createNotification(
      "Something went wrong",
      "The chat session could not be created"
    );
  }
};