import { Checkbox, CheckboxGroup, createStyles, Select, Textarea, TextInput } from "@mantine/core";
import { DatePicker, TimeInput } from "@mantine/dates"
import { useDebouncedValue } from "@mantine/hooks";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input/input'
import moment from "moment/moment";

import { findPatient, getStaffSubmitters, getUser, sendSMS, sendEmail,  } from "../../API";
import { OTHER, STAFF_RESPONDER } from "../../constants";
import { createNotification } from "../Notifications";
import { isEmailValid } from "../../utils";
import { showToastMessage, updateToastMessage } from './index';

const countryCode = process.env.REACT_APP_COUNTRY_CODE;

const useStyles = createStyles((theme, _params, getRef) => ({
  input: {
    width: "300px",
    marginLeft: "5.5rem",
    marginTop: '1rem'
  },
  checkboxGroup: {
    '& .mantine-Group-root': {
      display: 'flex',
      flexDirection: 'column'
    },
    '& .mantine-Group-child': {
      alignSelf: 'flex-start'
    }
  },
  label: {
    display: 'inline-block',
    fontSize: '14px',
    fontWeight: '500',
    color: '#212529',
    lineHeight: '1.55',
    fontFamily: 'Roboto,Open Sans,Arial',
    width: '20rem',
    marginTop: '1rem',
    marginLeft: '5.5rem',
    span: {
      color: '#f03e3e'
    }
},
  phoneInput: {
    boxSizing: 'border-box',
    minHeight: '36px',
    padding: '0 12px',
    border: '1px solid #ced4da',
    width: "300px",
    marginLeft: "5.5rem",
    marginTop: '3px',
    outline: 'none',
    fontFamily: 'Roboto',
    borderRadius: '4px',
    transition: 'border-color 100ms ease 0s',

    '&:focus': {
      border: '1px solid rgb(25,113,194)'
    }
  },
  sendBtn: {
    marginTop: '0.5rem',
    marginLeft: '11.5rem',
    background: 'rgb(0 145 0)',
    padding: '3px 12px',
    borderRadius: '5px',
    border: 'none',
    cursor: 'pointer',
    fontSize: '13px',
    fontWeight: '500',
    letterSpacing: '0.25px',
    lineHeight: '24px',
    width: '6rem',
    color: '#fff',
    outline: 'none'
  }

}));

const getDate = (value) => {
    const dateSplit = value.toLocaleDateString("en-GB").split('/');
    const date = dateSplit[0];
    const month = dateSplit[1];
    const year = dateSplit[2];
    return `${month}-${date}-${year}`;
};

const contactTimes = [
  '7am-12pm', '12pm-5pm', '5pm-9pm'
];

const schedulingStatus = [
  {label:'Scheduled', value: 'scheduled'},
  {label: 'Visit completed', value: 'Visit completed'},
  {label: 'No Show', value: 'No Show'},
  {label: 'Cancelled', value: 'Cancelled'},
];

const notEnrolledReasons = [
  {label: 'Patient declined', value: 'Patient declined'},
  {label: 'Patient has no technology ability', value: 'Patient has no technology ability'},
  {label: 'Other / Not appropriate', value: 'Other / Not appropriate'},
];

const sortLastNames = (obj1, obj2) => {
  const obj1LastName = obj1.last_name ? obj1.last_name.toLowerCase() : '';
  const obj2LastName = obj2.last_name ? obj2.last_name.toLowerCase() : '';

  if ( obj1LastName < obj2LastName ){
    return -1;
  }
  if ( obj1LastName > obj2LastName ){
    return 1;
  }
  return 0;
};

const PatientForm = ({form, activeReferral}) => {
  const { classes } = useStyles();
  const { token:auth_token, role, medical_group } = useSelector((state) => state.auth);

  const [names, setNames] = useState([]);
  const [patientPhone, setPatientPhone] = useState(null);
  const [patientsData, setPatientsData] = useState({});
  const [selectedPatient, setSelectedPatient] = useState(null);
  const [staffSubmitters, setStaffSubmitters] = useState([]);
  const [staffSubmittersDetails, setStaffSubmittersDetails] = useState([]);
  const [ debouncedDob ] = useDebouncedValue(form.values.dob, 500);

  const isReferralActive = () => {
    return Object.keys(activeReferral).length > 0 ? true : false;
  };

  useEffect(() => {
    const getPatient = async () => {
      const patient = await getUser(activeReferral.patient_id, auth_token);
      setSelectedPatient(patient);
    };

    if (isReferralActive()) {
      form.setFieldValue("dob", new Date(activeReferral.patient_dob));
      form.setFieldValue("contact_time", activeReferral.contact_time);
      form.setFieldValue("patient_email", activeReferral.patient_email);
      form.setFieldValue("coordinating_member", activeReferral.coordinating_member);
      form.setFieldValue("primary_care_provider", activeReferral.primary_care_provider);
      form.setFieldValue("reason_not_enrolling", activeReferral.reason_not_enrolling);
      form.setFieldValue("reason", activeReferral.reason);
      form.setFieldValue("guardian", activeReferral.guardian);
      form.setFieldValue("scheduling_status", activeReferral.scheduling_status);
      form.setFieldValue("notes", activeReferral.notes);
      form.setFieldValue("patient_mobile", activeReferral.patient_mobile);
      setPatientPhone(activeReferral.patient_mobile);
      getPatient();

      if (activeReferral.appointment_date && activeReferral.appointment_date.trim() !== '') {
        const aptDateAndTime = activeReferral.appointment_date.split(' ');
        if (aptDateAndTime[0]) {
          form.setFieldValue("apt_date", new Date(aptDateAndTime[0]));
        }
        if (aptDateAndTime[1]) {
          const time = aptDateAndTime[1].split(':');
          const apt_time = moment().set('hour', time[0]).set('minute', time[1]).toString();
          form.setFieldValue("apt_time", new Date(apt_time));
        }
      }
    }
  }, [activeReferral]);

  useEffect(() => {
    const fetchStaffSubmitters = async (medical_group) => {
      try {
        const data = await getStaffSubmitters(medical_group);
        const staffSubmitterOptions = [];
        data.sort(sortLastNames);
        data.forEach((obj) => {
          staffSubmitterOptions.push({
            label: obj.first_name + " " + obj.last_name,
            value: obj.user_id
          });
        });
        setStaffSubmitters(staffSubmitterOptions);
        setStaffSubmittersDetails(data);

      } catch (error) {
        console.log(error);
      }
    };
    if (medical_group) {
      fetchStaffSubmitters(medical_group);
    } else if (isReferralActive() && role === STAFF_RESPONDER && activeReferral.medical_group) {
      fetchStaffSubmitters(activeReferral.medical_group);
    }
  }, []);

  useEffect(() => {
    if(isReferralActive()) {
      form.setFieldValue("staff", activeReferral.staff);
      if (staffSubmittersDetails.length && activeReferral.staff) {
        const selectedStaffSubmitter = staffSubmittersDetails.find(staffSubmitter => {
          return staffSubmitter.user_id === activeReferral.staff;
        });
        form.setFieldValue('owner1_id', selectedStaffSubmitter.user_id);
        form.setFieldValue('owner1_agora_id', selectedStaffSubmitter.agora_id);
      }
    }
  }, [staffSubmitters, staffSubmittersDetails]);

  useEffect(() => {
    if (form.values.staff) {
      if (staffSubmittersDetails.length && form.values.staff) {
        const selectedStaffSubmitter = staffSubmittersDetails.find(staffSubmitter => {
          return staffSubmitter.user_id === form.values.staff;
        });
        form.setFieldValue('owner1_id', selectedStaffSubmitter.user_id);
        form.setFieldValue('owner1_agora_id', selectedStaffSubmitter.agora_id);
      }
    }
  }, [form.values.staff, staffSubmittersDetails]);

  useEffect(() => {
    const dob = debouncedDob
                ? typeof debouncedDob === 'object' ? getDate(debouncedDob) : ""
                : "";
    const getPatients = async () => {
      const { nameOptions, allPatientsData } = await findPatient(
        dob,
        "",
        auth_token
      );
      setNames(nameOptions);
      setPatientsData(allPatientsData);
    };

    if (dob && role !== OTHER) {
      getPatients();
    }
  }, [debouncedDob]);

  let patientGender = '';

  if (selectedPatient) {
    patientGender = selectedPatient.gender === 1 ? 'Male' : 'Female';
  }

  const onSendSMSClick = async () => {
    if(!patientPhone) {
      createNotification(
        "Error",
        "Invalid mobile number"
      );
      return;
    }
    const phoneNumber = patientPhone.trim();
    if (!phoneNumber || (phoneNumber.length !== 13 && phoneNumber.length !== 12)) {
      createNotification(
        "Error",
        "Invalid mobile number"
      );
      return;
    }
    try {
      showToastMessage('Sending text message...');
      await sendSMS(phoneNumber);
      updateToastMessage('Text message sent');
    } catch (error) {}
  };

  const onSendEmailClick = async () => {
    const email = form.values.patient_email;
    if (!email || !email.trim() || !isEmailValid(email)) {
      createNotification(
        "Error",
        "Invalid email"
      );
      return;
    }
    try {
      showToastMessage('Sending email message...');
      await sendEmail(email.trim());
      updateToastMessage('Email message sent');
    } catch (error) {}
  };

  const keyDownHandler = (e) => {
    if(e.keyCode == 13) {
        e.preventDefault();
        return;
    }
  };

  let doShowExtraFields = false;

  if (isReferralActive() && activeReferral.current_status === 'Enrolled') {
    doShowExtraFields = true;
  }

  return (
    <>
        <DatePicker
            initiallyOpened={false}
            className={classes.input}
            label="Date of Birth"
            locale="en"
            allowFreeInput
            required
            disabled={isReferralActive()}
            onKeyDown={keyDownHandler}
            {...form.getInputProps("dob")}
        />
        { isReferralActive() ? (
            <TextInput
            className={classes.input}
            label="Name"
            disabled={true}
            value={activeReferral.patient_name}
          />
          ) : ( <Select
            className={classes.input}
            label="Name"
            searchable={true}
            disabled={!form.values.dob}
            data={names}
            clearable
            onChange={(value) => {
              if (value) {
                const patient = patientsData.find(patient => patient.user_id === value);
                setSelectedPatient(patient);
                form.setFieldValue("patient_id", patient.user_id);
                form.setFieldValue("patient_name", patient.first_name + " " + patient.last_name);
                form.setFieldValue("patient_agora_id", patient.agora_id);
              }
            }}
        />)}
        <TextInput
            className={classes.input}
            label="Sex"
            disabled={true}
            value={patientGender}
        />
        <TextInput
            className={classes.input}
            label="Member ID"
            disabled={true}
            value={selectedPatient?.member_identifier}
        />
        <Textarea
            className={classes.input}
            label="Address"
            disabled={true}
            rows={4}
            value={selectedPatient?.address}
        />
        <TextInput
            className={classes.input}
            label="City"
            disabled={true}
            value={selectedPatient?.city}
        />
        <TextInput
            className={classes.input}
            label="State"
            disabled={true}
            value={selectedPatient?.state}
        />
        <TextInput
            className={classes.input}
            label="Zip Code"
            disabled={true}
            value={selectedPatient?.zipcode}
        />
        <TextInput
            className={classes.input}
            label="Country"
            disabled={true}
            value={selectedPatient?.country}
        />
        <label className={classes.label}>
          Enter Mobile Phone<span> *</span>
        </label>
        <PhoneInput
          withCountryCallingCode
          international
          country={countryCode}
          className={classes.phoneInput}
          value={patientPhone}
          onKeyDown={keyDownHandler}
          onChange={(value) => {
            setPatientPhone(value);
            form.setFieldValue("patient_mobile", value);
          }}
        />
        <button
          type="button"
          className={classes.sendBtn}
          onClick={onSendSMSClick}
        >
          Send SMS
        </button>
        <TextInput
            style={{}}
            label="Enter Email Address"
            className={classes.input}
            onKeyDown={keyDownHandler}
            {...form.getInputProps("patient_email")}
        />
        <button
          type="button"
          className={classes.sendBtn}
          onClick={onSendEmailClick}
        >
          Send Email
        </button>
        <CheckboxGroup
          className={classes.input}
          label="Best time of day to contact"
          classNames={{root: classes.checkboxGroup}}
          value={form.values.contact_time}
          onChange={(value) => form.setFieldValue("contact_time", value)}
        >
          {contactTimes.map((time, index) => {
            return (
              <Checkbox value={time} label={time} key={index}/>
            );
          })}
        </CheckboxGroup>
        <Select
          className={classes.input}
          label="Patient or Family Coordinating"
          data={[
            { value: 'patient', label: 'Patient' },
            { value: 'family', label: 'Family' },
          ]}
          {...form.getInputProps("coordinating_member")}
        />
        <TextInput
          className={classes.input}
          label="Guardian Last Name, First Name (if applicable)"
          onKeyDown={keyDownHandler}
          {...form.getInputProps("guardian")}
        />
        <Select
          className={classes.input}
          label="Hospital Staff contact person (Receive closed loop communications/notifications)"
          data={staffSubmitters}
          required
          {...form.getInputProps("staff")}
        />
        <TextInput
          className={classes.input}
          label="Patient's Primary Care Provider"
          onKeyDown={keyDownHandler}
          {...form.getInputProps("primary_care_provider")}
        />
        <Select
          className={classes.input}
          label="Reason for not enrolling"
          data={notEnrolledReasons}
          {...form.getInputProps("reason_not_enrolling")}
        />
        <Textarea
            className={classes.input}
            required
            label="Reasons for Referral"
            rows={4}
            {...form.getInputProps("reason")}
        />
        {doShowExtraFields && (
          <>
            <DatePicker
              initiallyOpened={false}
              className={classes.input}
              label="Appointment Date"
              locale="en"
              allowFreeInput
              onKeyDown={keyDownHandler}
              {...form.getInputProps("apt_date")}
            />
            <TimeInput
              className={classes.input}
              label="Appointment Time"
              {...form.getInputProps("apt_time")}
            />
            <Select
              className={classes.input}
              label="Scheduling Status"
              data={schedulingStatus}
              {...form.getInputProps("scheduling_status")}
            />
            <Textarea
              className={classes.input}
              label="Notes"
              rows={4}
              {...form.getInputProps("notes")}
            />
          </>
        )}
    </>
  )
}

export default PatientForm;