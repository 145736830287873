import { MantineProvider } from "@mantine/core";
import { Dashboard } from "./components/Dashboard/index.js";
import { Layout } from "./components/Layout/index.js";
import { useEffect } from "react";
import "./App.css";

function App() {
  return <Layout className="fill-window" />;
}

export default App;
